import { FC } from "react";
import { useAuth } from "react-oidc-context";
import { Link, NavLink, useMatch, useResolvedPath } from "react-router-dom";
import { useGetIsAdminQuery } from "../hooks/adminHooks";
import classes from "./NavBar.module.scss";

const MyNavLink: FC<{ to: string; title: string }> = ({ to, title }) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });
  return (
    <NavLink
      to={to}
      className={classes.link + " " + (match ? classes.active : "")}
    >
      {title}
    </NavLink>
  );
};

export const NavBar = () => {
  const auth = useAuth();
  const logout = () => {
    auth.signoutRedirect();
  };  

  const isAdminQuery = useGetIsAdminQuery();
  const isAdmin = isAdminQuery.data;

  const routes = [
    {
      to: "/",
      title: "Home",
    },
  ];
  const adminRoutes = [
    {
      to: "/admin",
      title: "Admin",
    },
    {
      to: "/config",
      title: "Configuration",
    },
  ];

  return (
    <div className="bg-primary">
      <nav className="navbar navbar-expand-lg navbar-dark container">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand">
            <img
              className="img-fluid"
              id="navbar-logo"
              alt="Snow College Logo"
              src="/SnowCollegeLogo.png"
            />
          </Link>

          <button
            className={classes.navbar_toggler + " btn btn-bold navbar-toggler"}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto">
              {routes.map((r) => (
                <MyNavLink key={r.to} {...r} />
              ))}
              {isAdmin &&
                adminRoutes.map((r) => <MyNavLink key={r.to} {...r} />)}
              <li className="nav-item my-auto">
                <button className={classes.logout} onClick={logout}>
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};
